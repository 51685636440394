import React, { useMemo, useState } from "react";
// import img from "./imgs/";
import "./App.css";
function App() {
  const [key, setKey] = useState("");
  const [list, setList] = useState([
    {
      key: "af",
      url: "/af/",
      title: "安捷云产品",
      storage: "帮助中心",
      img: "help",
    },
    {
      key: "sjlyq",
      url: "/sjlyq/",
      title: "数据路由器",
      storage: "帮助中心",
      img: "help",
    },
    {
      key: "fastbi",
      url: "/fastbi/",
      // url: "/fastbi/产品概述/基本概念/",
      title: "FastBI产品",
      storage: "使用手册",
      img: "use",
    },
    {
      key: "xzapi",
      url: "/xzapi/",
      title: "小智API服务",
      storage: "产品手册",
      img: "use",
    },
    {
      key: "xiaozhi",
      url: "/xiaozhi/",
      title: "小智数据产品",
      storage: "使用手册",
      img: "use",
    },
    {
      key: "pzt",
      url: "/pzt/",
      title: "凭证通",
      storage: "产品手册",
      img: "use",
    },
    {
      key: "xcyl",
      url: "/xcyl/",
      title: "小畅e联",
      storage: "产品手册",
      img: "use",
    },
    {
      key: "yytj",
      url: "/yytj/",
      title: "天智FastBI",
      storage: "应用手册",
      img: "use",
    },
    {
      key: "cjt",
      url: "/cjt/",
      // url: "https://editdoc.tizdata.com/document/index?document_id=1092",
      title: "畅捷通连接器",
      storage: "",
      img: "use",
    },
    {
      key: "xzkfzpt",
      url: "/xzkfzpt/",
      title: "小智",
      storage: "开发平台",
      img: "help",
    },
    // {
    //   key: "bmyszx",
    //   url: "/bmyszx/",
    //   title: "部门预算执行",
    //   storage: "分析手册",
    //   img: "use",
    // },
    // {
    //   key: "bzcw",
    //   url: "/bzcw/",
    //   title: "标准财务",
    //   storage: "分析手册",
    //   img: "use",
    // },
    // {
    //   key: "hbbb",
    //   url: "/hbbb/",
    //   title: "合并报表",
    //   storage: "手册",
    //   img: "use",
    // },
  ]);

  const sourchList = useMemo(() => {
    return list.filter(
      (item) => item.storage.includes(key) || item.title.includes(key)
    );
  }, [list, key]);

  return (
    <div className="body_background">
      <img className="banner" src="./imgs/banner.png" alt="" />
      <main className="main">
        <div className="input_title">
          <h1 className="title">产品文档学习</h1>
          <div className="input">
            <img className="sourch" src="./imgs/sourch.png" alt="" />
            <input
              type="text"
              placeholder="请输入关键字"
              onChange={(e) => {
                setKey(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="list">
          {sourchList.map((item) => (
            <div>
              <a
                key={item.key}
                href={item.url}
                className="list_a"
                target="_blank"
              >
                <img
                  className="list_img"
                  src={
                    item.img === "help"
                      ? "./imgs/helpcenter.png"
                      : "./imgs/use_book.png"
                  }
                  alt=""
                />
                <div className="list_tilte_storage">
                  <span>{item.title}</span>
                  <span className="storage">{item.storage}</span>
                </div>
              </a>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}

export default App;
